<template>
  <div class="flex flex-col gap-5 w-full">
    <div class="flex flex-col gap-1 w-full">
      <span>Code</span>
      <vs-input
        v-model="selectedInvoiceExhange.invoiceExchange.code"
        readonly
        class="w-full"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <span>Nomor Tukar Faktur</span>
      <vs-input
        v-model="selectedInvoiceExhange.invoiceExchange.invoice_exchange_number"
        readonly
        class="w-full"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <span>Tanggal Tukar Faktur</span>
      <vs-input v-model="exchangeDate" readonly class="w-full" />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <span>Sold To</span>
      <vs-input v-model="soldTo" readonly class="w-full" />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <span>Ship To</span>
      <vs-input v-model="shipTo" readonly class="w-full" />
    </div>
    <vs-divider />
    <div class="flex flex-col gap-4 w-full">
      <span class="font-bold">Schedules</span>
      <schedule-display :schedules="customerInvoiceExchange.schedules" />
    </div>
    <vs-divider />
    <div
      class="flex flex-col gap-4 w-full"
      style="max-height: 300px; overflow: auto"
    >
      <!-- <span>Invoices</span> -->
      <vs-table :data="invoices">
        <template slot="thead">
          <vs-th> Invoice </vs-th>
          <!-- <vs-th> Name </vs-th>
          <vs-th> Website </vs-th> -->
          <vs-th> Amount </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              Code : {{ tr.Code }}
              <br />
              Faktur : {{ tr.FakturNumber }}
              <br />
              Invoice Reference Code :
              {{ tr.InvoiceReferenceCode ? tr.InvoiceReferenceCode : "-" }}
              <br />
              Date : {{ dateFormat(tr.Date) }}
              <br />
              Due Date : {{ dateFormat(tr.DueDate) }}
              <br />
              <!-- Posting Date : {{ dateFormat(invoice.PostingDate) }}
            <br /> -->
              Created Date : {{ dateFormat(tr.CreatedAt) }}
            </vs-td>
            <!-- <vs-td>
              Name : {{ record.invoice.client_name }}
              <br />
              Code : {{ record.invoice.client_code }}
              <br />
              Territory : {{ getTerritoryName(record.invoice.territory_id) }}

              <br />
            </vs-td>
            <vs-td>
              Name : {{ record.customer_address.contact_name }}
              <br />
              Code : {{ record.customer_address.code }}
              <br />
              Address :
              {{
                `${record.customer_address.address}, ${record.customer_address.sub_district}, ${record.customer_address.sub_district}, ${record.customer_address.city}`
              }}
              <br />
              Territory :
              {{ getTerritoryName(record.customer_address.territory_id) }}
              <br />
            </vs-td> -->
            <vs-td>
              Subtotal : {{ priceFormat(tr.Subtotal) }}
              <br />
              Discount : {{ priceFormat(tr.Discount) }}
              <br />
              Subtotal Charge : {{ priceFormat(tr.Subtotal) }}
              <br />
              Tax : {{ priceFormat(tr.TaxAmount) }}
              <br />
              Total : {{ priceFormat(tr.Total) }}
              <br />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import scheduleDisplay from "../schedule-display.vue";
export default {
  components: {
    "schedule-display": scheduleDisplay,
  },
  props: {
    selectedInvoiceExhange: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      invoices: [],
      exchangeDate: "",
      soldTo: "",
      shipTo: "",
      customerInvoiceExchange: {},
      //   itemList
    };
  },
  async mounted() {
    console.log(this.selectedInvoiceExhange);
    await this.getInvoices();
    await this.getSchedule();
    this.exchangeDate = moment(
      this.selectedInvoiceExhange.invoiceExchange.date
    ).format("YYYY-MM-DD");
    this.soldTo = `${this.selectedInvoiceExhange.invoiceExchange.client_code} - ${this.selectedInvoiceExhange.invoiceExchange.client_name}`;
    this.shipTo = `${this.selectedInvoiceExhange.customer_address.code} - ${this.selectedInvoiceExhange.customer_address.contact_name}`;
  },
  methods: {
    async getInvoices() {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("api/v1/invoice/exchange/detail", {
          params: {
            codes: this.selectedInvoiceExhange.lines.map(
              (line) => line.invoice_code
            ),
            customer_code:
              this.selectedInvoiceExhange.invoiceExchange.client_code,
          },
        });

        if (resp.code < 299) {
          this.invoices = resp.data;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
    dateFormat(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    async getSchedule() {
      try {
        this.$vs.loading();
        const resp = await this.$http.get(
          `api/v1/customer-invoice-exchange/${this.selectedInvoiceExhange.invoiceExchange.customer_invoice_exchange_id}`
        );

        if (resp.code < 299) {
          this.customerInvoiceExchange = resp.data.data;
          console.log(this.customerInvoiceExchange);
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
    async getCustomerAddress() {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("api/v1/customer/address", {
          params: {
            customer_code:
              this.selectedInvoiceExhange.invoiceExchange.client_code,
          },
        });

        if (resp.code < 299) {
          this.soldTo = `${this.selectedInvoiceExhange.invoiceExchange.client_code} - ${this.selectedInvoiceExhange.invoiceExchange.client_name}`;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
  },
};
</script>
